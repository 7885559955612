import React, { Component } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout/Layout';

import WafaImage from '../images/elwafalanding.jpg';
import WafaApp1 from '../images/elwafaapp1.jpg';
import WafaApp2 from '../images/elwafaapp2.jpg';
import ToastedBuns from '../images/toastedbunslanding.jpg';
import SocialApe from '../images/socialapelanding.jpg';

class work extends Component {
  render() {
    return (
      <Layout pageTitle="Projects I worked on">
        <h1 className="section-title">Work</h1>
        <p className="subtitle">
          Here, you'll find projects that I've worked on
        </p>
        <hr className="horizontal-ruler" />
        <div className="row project-row">
          <div className="col-lg-8">
            <Link to="/work/el-wafa-website">
              <img src={WafaImage} alt="El wafa website" />
            </Link>
          </div>
          <div className="col-lg-4">
            <h3>
              <Link to="/work/el-wafa-website">El Wafa Wesbite</Link>
            </h3>
            <p>
              A trading company's website that informs users and potential
              clients about the company and the products they sell.
            </p>
            <Link
              style={{ margin: '0' }}
              to="/work/el-wafa-website"
              className="btn btn-default btn-lg custom-button"
            >
              Read more
            </Link>
          </div>
        </div>
        <hr className="horizontal-ruler" />
        <div className="row project-row">
          <div className="col-lg-4">
            <h3>
              <Link to="/work/el-wafa-app">El Wafa Mobile App</Link>
            </h3>
            <p>
              A mobile app that connects to the company's backend displays
              products to logged in clients and allows them to browse and rate
              the products and contact the company for support.
            </p>
            <Link
              style={{ margin: '0 0 20px 0' }}
              to="/work/el-wafa-app"
              className="btn btn-default btn-lg custom-button"
            >
              Read more
            </Link>
          </div>
          <div className="col-lg-8">
            <Link to="/work/el-wafa-app">
              <img
                src={WafaApp1}
                alt="app 1"
                style={{ maxHeight: 400, marginRight: 30 }}
              />
              <img src={WafaApp2} alt="app 2" style={{ maxHeight: 400 }} />
            </Link>
          </div>
        </div>
        <hr className="horizontal-ruler" />
        <div className="row project-row">
          <div className="col-lg-8">
            <Link to="/work/toasted-buns">
              <img src={ToastedBuns} alt="toastedbuns website" />
            </Link>
          </div>
          <div className="col-lg-4">
            <h3>
              <Link to="/work/toasted-buns">Toasted Buns</Link>
            </h3>
            <p>
              A weekly podcast and a blog website that serves podcast audio
              files, blog posts from markdown and is statically generated and
              optimised for SEO.
            </p>
            <Link
              style={{ margin: '0' }}
              to="/work/toasted-buns"
              className="btn btn-default btn-lg custom-button"
            >
              Read more
            </Link>
          </div>
        </div>
        <hr className="horizontal-ruler" />
        <div className="row project-row">
          <div className="col-lg-4">
            <h3>
              <Link to="/work/social-ape">Social Ape (demo)</Link>
            </h3>
            <p>
              A social media web app that lets users post new screams, like and
              comment on posts, login, register and interact with other users.
            </p>
            <Link
              style={{ margin: '0 0 20px 0' }}
              to="/work/social-ape"
              className="btn btn-default btn-lg custom-button"
            >
              Read more
            </Link>
          </div>
          <div className="col-lg-8">
            <Link to="/work/social-ape">
              <img
                src={SocialApe}
                alt="app 1"
                style={{ maxHeight: 400, marginRight: 30 }}
              />
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default work;
